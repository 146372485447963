import React, {Component, useEffect, useState} from "react";
import Lightbox from 'react-18-image-lightbox';
import 'react-18-image-lightbox/style.css';

import {
    Col, Container, Row, Modal, ModalHeader, ModalBody, Nav,
    NavbarBrand,
    NavbarToggler,
    Collapse,
    NavItem
} from "reactstrap";
import ModalVideo from "react-modal-video";
import "../../../node_modules/react-modal-video/scss/modal-video.scss";
import axios from "axios";

import { Link } from 'react-scroll';

import {Link as Link2, useSearchParams} from 'react-router-dom';
import {useParams} from 'react-router';

import Image1 from "../../assets/images/portfolio/11.jpg";
import Image2 from "../../assets/images/portfolio/12.jpg";
import Image3 from "../../assets/images/portfolio/13.jpg";
import Image4 from "../../assets/images/portfolio/14.jpg";
import Image5 from "../../assets/images/portfolio/15.jpg";
import Image6 from "../../assets/images/portfolio/16.jpg";
import Image7 from "../../assets/images/portfolio/17.jpg";
import Image8 from "../../assets/images/portfolio/18.jpg";
import Image9 from "../../assets/images/portfolio/19.jpg";
import Logo from "../../assets/images/logo-icon-64.png";

import * as Icon from 'react-feather';
import Logodark from "../../assets/images/logo-dark.png";
import Logolight from "../../assets/images/logo-light.png";

export const Portfolio = () => {
    const [complex, setComplex] = useState([])
    const [toggleMenu, setToggleMenu] = useState(false)
    const [option_1, setOption_1] = useState([])
    const [option_2, setOption_2] = useState([])
    const [searchParams] = useSearchParams();
    useEffect(() => {
        console.log(searchParams);
        axios.get(`https://team-backend-new.tds.media/telegram/get_products/?day=${searchParams.get('day')}&week=${searchParams.get('week')}`).then(
            res => {
                console.log(res.data);
                setComplex(res.data.complex);
                setOption_1(res.data.option1);
                setOption_2(res.data.option2);
            }
        ).catch(
            err=>{console.log(err)}
        )
    },[])

    return (
            <div>
                <nav id="navbar" className="navbar navbar-expand-lg fixed-top sticky">
                    <div className="container" style={{background:"white"}}>
                        <NavbarBrand className="navbar-brand" href="/">
                            {/*<p style={{width:30}}><span style={{color:"orange"}}>TDS</span> Cafeteria</p>*/}
                            {/*<img src={Logodark} className="logo-light-mode" alt="" />*/}
                            {/*<img src={Logolight} className="logo-dark-mode" alt="" />*/}
                        </NavbarBrand>
                        <NavbarToggler onClick={()=>{setToggleMenu(!toggleMenu)}}>
                            <Icon.Menu />
                        </NavbarToggler>

                        <Collapse className="navbar-collapse" isOpen={toggleMenu} id="navbarSupportedContent">
                            <Nav className="navbar-nav mx-auto mb-2 mb-lg-0" id="navbar-navlist">
                                <NavItem>
                                    <Link activeClass="active" spy={true} smooth={true} duration={500} className="nav-link" to="complex">Комплекс</Link>
                                </NavItem>
                                <NavItem>
                                    <Link activeClass="active" spy={true} smooth={true} duration={500} className="nav-link" to="options_1">Закуски 1</Link>
                                </NavItem>
                                <NavItem>
                                    <Link activeClass="active" spy={true} smooth={true} duration={500} className="nav-link" to="options_2">Закуски 2</Link>
                                </NavItem>
                            </Nav>

                        </Collapse>

                    </div>
                </nav>



                <section className="bg-half d-table w-100" id="home">
                    <Container className="mt-0" id="complex" >
                        <h3>Комплексы на {searchParams.get('date')}</h3>
                        <Row id="grid" className=" g-4" style={{marginTop:20, marginRight:20, marginLeft:20}}>
                            {complex.map((item, index) => {
                                if (item.soup !== ''){
                                    return(
                                        <div className="col-lg-4 col-md-6 col-12 picture-item" key={index} style={{border:"1px solid black", borderRadius:6}}>
                                            <div
                                                className="card border-0 project project-primary position-relative d-block overflow-hidden rounded">
                                                <h4>Золото</h4>
                                                <p> {item.soup}</p>
                                                <p> {item.second_dish}</p>
                                                <p>{item.salad}</p>
                                                <p>{item.drink}</p>
                                                <p>{item.snack}</p>
                                            </div>
                                        </div>
                                    )
                                }else{
                                    return(
                                        <div className="col-lg-4 col-md-6 col-12 picture-item" key={index} style={window.innerWidth > 600 ? {border:"1px solid black", borderRadius:6, marginLeft:10} : {border:"1px solid black", borderRadius:6}}>
                                            <div
                                                className="card border-0 project project-primary position-relative d-block overflow-hidden rounded">
                                                <h4>Серебро</h4>
                                                <p> {item.second_dish}</p>
                                                <p>{item.salad}</p>
                                                <p>{item.drink}</p>
                                                <p>{item.snack}</p>
                                            </div>
                                        </div>
                                    )
                                }
                            })}

                        </Row>
                    </Container>

                    <Container className="mt-10" id="options_1" style={{paddingTop:100}}>
                        <h3>Блюда опции 1</h3>
                        <Row id="grid" className=" g-4">
                            {option_1.map((item, index) => (
                                <div className="col-lg-4 col-md-6 col-12 picture-item" key={index}>
                                    <h4>{item.name}</h4>
                                    <div className="card border-0 project project-primary position-relative d-block overflow-hidden rounded">
                                        <div className="card-body p-0" style={{height:400}}>
                                            <img src={`https://team-backend-new.tds.media/${item.image}`} style={{
                                                width:'100%', height:"100%", objectFit:"contain"
                                            }} className="img-fluid" alt="workimage" />
                                            <div className="overlay-work bg-dark"></div>
                                            <div className="content bg-white p-3 rounded shadow start-0 end-0 bottom-0 m-3">
                                                <h5 to="#" className="text-dark title h5 ">{item.name}</h5>
                                                <p className="text-muted fw-normal mt-2 tag mb-0" style={{
                                                    display: "-webkit-box -webkit-box-orient: vertical; -webkit-line-clamp: 3",
                                                    overflow: "hidden",
                                                    fontSize:10
                                                }}>{item.description}</p>
                                            </div>
                                            {/*<div className="icons text-center">*/}
                                            {/*    <Link2 to="#" onClick={() => this.setState({ isOpen: true, photoIndex: index })} className="btn btn-icon btn-pills lightbox"><Icon.Camera className="fea icon-sm image-icon" /></Link2>*/}
                                            {/*</div>*/}
                                        </div>
                                    </div>
                                </div>
                            ))}

                        </Row>
                        {/*<Row className="text-center">*/}
                        {/*    <div className="col-12 mt-4 pt-2">*/}
                        {/*        <Link2 to="#" className="btn btn-pills btn-primary">See works</Link2>*/}
                        {/*    </div>*/}
                        {/*</Row>*/}
                    </Container>

                    <Container className="mt-100 mt-60" id="options_2" style={{paddingTop:100}}>
                        <h3>Блюда опции 2</h3>
                        <Row id="grid" className=" g-4">
                            {option_2.map((item, index) => (
                                <div className="col-lg-4 col-md-6 col-12 picture-item" key={index} style={{marginBottom:20}}>
                                    <h4>{item.name}</h4>
                                    <div className="card border-0 project project-primary position-relative d-block overflow-hidden rounded">
                                        <div className="card-body p-0" style={{height:400}}>
                                            <img src={`https://team-backend-new.tds.media/${item.image}`} style={{
                                                width:'100%', height:"100%", objectFit:"contain"
                                            }} className="img-fluid" alt="workimage" />
                                            <div className="overlay-work bg-dark"></div>
                                            <div className="content bg-white p-3 rounded shadow start-0 end-0 bottom-0 m-3">
                                                <h5 to="#" className="text-dark title h5 ">{item.name}</h5>
                                                <p className="text-muted fw-normal mt-2 tag mb-0" style={{
                                                    display: "-webkit-box -webkit-box-orient: vertical; -webkit-line-clamp: 3",
                                                    overflow: "hidden",
                                                    fontSize:10
                                                }}>{item.description}</p>
                                            </div>
                                            {/*<div className="icons text-center">*/}
                                            {/*    <Link2 to="#" onClick={() => this.setState({ isOpen: true, photoIndex: index })} className="btn btn-icon btn-pills lightbox"><Icon.Camera className="fea icon-sm image-icon" /></Link2>*/}
                                            {/*</div>*/}
                                        </div>
                                    </div>
                                </div>
                            ))}

                        </Row>
                    </Container>
                </section>


                {/* footer start  */}
                <footer className="footer bg-footer">
                    <div className="footer-py-30 footer-bar">
                        <Container className="text-center">
                            <div className="row align-items-center">
                                <div className="col">
                                    <p className="mb-0">© {(new Date().getFullYear())}{" "} TDS Media design.</p>
                                </div>
                            </div>
                        </Container>
                    </div>
                </footer>
            </div>
    )
}